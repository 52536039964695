import React, { useState } from 'react'
import './choose-for-purifier.scss'
import NormalInput from '../../common/inputs/NormalInput'
import { useNavigate } from 'react-router-dom'

function ChooseForPurifier({ setModal }) {
  const [form, setForm] = useState({
    from_date: new Date().toISOString().split('T')[0],
    to_date: new Date().toISOString().split('T')[0],
    cid: ''
  })
  const navigate = useNavigate()

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate('/purifier/services-list', { state: form })
    setModal({ status: false })
  }

  return (
    <div className='choose-for-purifier-div'>
      <form action="" onSubmit={handleSubmit}>
        <NormalInput label='From date' type={'date'} name='from_date' value={form?.from_date || ''} onChangeFun={handleChange}
          min={'2022-10-11'} max={form?.to_date} />
        <NormalInput label='To date' type={'date'} name='to_date' value={form?.to_date || ''} onChangeFun={handleChange}
          min={'2022-10-11'} max={new Date().toISOString().split('T')[0]} />
        <NormalInput label='Customer ID' type={'number'} name='cid' value={form?.cid || ''} onChangeFun={handleChange} isRequired={false}
        placeholder={'Customer ID'} />
        <div className="button-div">
          <button type='submit'>Select</button>
        </div>
      </form>
    </div>
  )
}

export default ChooseForPurifier