import React, { useState } from 'react'
import NormalInput from '../../common/inputs/NormalInput'
import SelectInput from '../../common/inputs/SelectInput'
import './spares-form.scss'
import { BiLoaderAlt } from 'react-icons/bi'
import { purifierAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'
import { purifierSpareCategory } from '../../../assets/javascript/const-data'


function SparesForm({ data, setData, setModal }) {
    const [form, setForm] = useState(data || {})
    const [loading, setLoading] = useState(false)
    const categoryList = purifierSpareCategory.map((option) => {
        return {
            option: option,
            value: option,
            selected: data?.spare_category === option
        }
    })

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleCheck = (e) => {
        if (e.target.checked) {
            setForm({
                ...form,
                [e.target.name]: [...form[e.target.name] || [], e.target.value]
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: form[e.target.name].filter((elem) => elem !== e.target.value)
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (form.warranty_for?.length > 0 && !form.warranty_period_days) {
            toast.error('Fill warranty period')
            return;
        }

        setLoading(true)
        if (data) {
            purifierAxios.put('/spares', form).then(() => {
                setData((state) => state.map((item) => {
                    if (item._id === form._id) {
                        return form
                    }
                    return item
                }))
                setModal((state) => ({ ...state, status: false }))
                toast.success('Spare Updated')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        } else {
            purifierAxios.post('/spares', form).then((response) => {
                setData((state) => [response.data, ...state])
                setModal((state) => ({ ...state, status: false }))
                toast.success('Spare Created')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        }
    }
    return (
        <div className='add-edit-spares-form'>
            <form action="" onSubmit={handleSubmit}>
                <div>
                    <NormalInput label='Spare name' name='spare_name' value={form?.spare_name || ''} onChangeFun={handleChange} />
                    <NormalInput label='Brand' name='brand' value={form?.brand || ''} onChangeFun={handleChange} />
                    <NormalInput label='Tally spare name' name='tally_spare_name' value={form?.tally_spare_name || ''} onChangeFun={handleChange} />
                    <SelectInput label='Category' name='spare_category' firstOption={{ option: 'Choose', value: '' }} values={categoryList} onChangeFun={handleChange} />
                    <div className="checkbox-list">
                        <div className="checkbox-div">
                            <div className="items">
                                <input type="checkbox" checked={form?.spare_types?.includes('Primary')} id="Primary" name='spare_types'
                                    value="Primary" onChange={handleCheck} />
                                <label htmlFor="Primary">Primary</label>
                            </div>
                            <div className="items">
                                <input type="checkbox" checked={form?.spare_types?.includes('Secondary')} id="Secondary" name='spare_types'
                                    value="Secondary" onChange={handleCheck} />
                                <label htmlFor="Secondary">Secondary</label>
                            </div>
                            <div className="items">
                                <input type="checkbox" checked={form?.spare_types?.includes('Special')} id="Special" name='spare_types'
                                    value="Special" onChange={handleCheck} />
                                <label htmlFor="Special">Special work</label>
                            </div>
                        </div>
                        <label className='head-label' htmlFor="">Spare types</label>
                    </div>
                    <div className="checkbox-list">
                        <div className="checkbox-div">
                            <div className="items">
                                <input type="checkbox" checked={form?.warranty_for?.includes('AMC')} id="AMC" name='warranty_for'
                                    value="AMC" onChange={handleCheck} />
                                <label htmlFor="AMC">AMC</label>
                            </div>
                            <div className="items">
                                <input type="checkbox" checked={form?.warranty_for?.includes('SSP')} id="SSP" name='warranty_for'
                                    value="SSP" onChange={handleCheck} />
                                <label htmlFor="SSP">SSP</label>
                            </div>
                            <div className="items">
                                <input type="checkbox" checked={form?.warranty_for?.includes('I/W')} id="I/W" name='warranty_for'
                                    value="I/W" onChange={handleCheck} />
                                <label htmlFor="I/W">I/W</label>
                            </div>
                            <div className="items">
                                <input type="checkbox" checked={form?.warranty_for?.includes('O/W')} id="O/W" name='warranty_for'
                                    value="O/W" onChange={handleCheck} />
                                <label htmlFor="O/W">O/W</label>
                            </div>
                            <div className="items">
                                <input type="checkbox" checked={form?.warranty_for?.includes('O/C')} id="O/C" name='warranty_for'
                                    value="O/C" onChange={handleCheck} />
                                <label htmlFor="O/C">O/C</label>
                            </div>

                        </div>
                        <label className='head-label' htmlFor="">Warranty for</label>
                    </div>
                    <NormalInput label='Warranty period (days)' type='Number' name='warranty_period_days' value={form?.warranty_period_days || ''} onChangeFun={handleChange}
                        isRequired={false} />
                    <NormalInput label='Normal Rate / Sales rate' type='Number' name='normal_rate' value={form?.normal_rate || ''} onChangeFun={handleChange} />
                    <NormalInput label='SSP Rate' name='ssp_rate' type='Number' value={form?.ssp_rate || ''} onChangeFun={handleChange} />
                    <NormalInput label='SSP Discount (%)' type='Number' name='ssp_disc_pct' value={form?.ssp_disc_pct || ''} onChangeFun={handleChange} />
                    <NormalInput label='Purchase rate' type='Number' name='purchase_rate' value={form?.purchase_rate || ''} onChangeFun={handleChange} />
                </div>
                <div className="button-div">
                    <button>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : data ? 'Update' : 'Create'}</button>
                </div>
            </form>
        </div>
    )
}

export default SparesForm