import React from 'react'
import { useEffect } from 'react';
import { loginUser } from '../redux/features/authSlice'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Home from '../pages/home/Home';
import NotFound from '../pages/404/NotFound';
import Tally from '../pages/tally/Tally';
import CustomerLedgers from '../pages/customer-ledgers/CustomerLedgers';
import TechnicianLedgers from '../pages/technician-ledgers/TechnicianLedgers';
import SparesList from '../pages/spares-list/SparesList';
import XmlDownload from '../pages/xml/XmlDownload';
import CreditList from '../pages/credit-list/CreditList';
import ComplimentList from '../pages/compliment-list/ComplimentList';
import PurifierServiceList from '../pages/purifier-service-list/PurifierServiceList';
import SinglePurifierService from '../pages/purifier-service-single/SingleService';
import CreditWork from '../pages/credit-work/CreditWork';
import PurifierPage from '../pages/purifier-page/PurifierPage';
import PurifierProductList from '../pages/product-list/PurifierProductList';
// import CreateProduct from '../pages/product-list/CreateProduct';
import WholeHousePage from '../pages/wh-page/WholeHousePage';
import WhProductList from '../pages/wh-product-list/WhProductList';
import PendingPayments from '../pages/pending-payments/PendingPayments';

function User() {
  const { user } = useSelector((state) => state.userAuth)
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch()
  let isAuthenticated = false

  if (user?.token) {
    isAuthenticated = true
  }

  useEffect(() => {
    const id = searchParams.get('id');
    if (!id && !user?._id) {
      window.location.href = `https://www.staff.alliancewatersolutions.com/`
    } else if (id || user?._id) {
      dispatch(loginUser(id || user?._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Routes>
      <Route path='/' element={<PrivateRoute element={<Home />} isAuthenticated={isAuthenticated} />} />
      {/* Tally */}
      <Route path='/tally' element={<PrivateRoute element={<Tally />} isAuthenticated={isAuthenticated} />} />
      <Route path='/tally/xml' element={<PrivateRoute element={<XmlDownload />} isAuthenticated={isAuthenticated} />} />
      <Route path='/tally/customer-ledgers' element={<PrivateRoute element={<CustomerLedgers />} isAuthenticated={isAuthenticated} />} />
      <Route path='/tally/technician-ledgers' element={<PrivateRoute element={<TechnicianLedgers />} isAuthenticated={isAuthenticated} />} />

      {/* Purifier */}
      <Route path='/purifier' element={<PrivateRoute element={<PurifierPage />} isAuthenticated={isAuthenticated} />} />
      <Route path='/purifier/spares-list' element={<PrivateRoute element={<SparesList />} isAuthenticated={isAuthenticated} />} />
      <Route path='/purifier/services-list' element={<PrivateRoute element={<PurifierServiceList />} isAuthenticated={isAuthenticated} />} />
      <Route path='/purifier/services-list/:srl_number' element={<PrivateRoute element={<SinglePurifierService />} isAuthenticated={isAuthenticated} />} />
      <Route path='/purifier/product-list' element={<PrivateRoute element={<PurifierProductList />} isAuthenticated={isAuthenticated} />} />
      {/* <Route path='/purifier/product-list/create' element={<PrivateRoute element={<CreateProduct />} isAuthenticated={isAuthenticated} />} /> */}

      {/* Whole house */}
      <Route path='/whole-house' element={<PrivateRoute element={<WholeHousePage />} isAuthenticated={isAuthenticated} />} />
      <Route path='/whole-house/product-list' element={<PrivateRoute element={<WhProductList />} isAuthenticated={isAuthenticated} />} />

      {/* Credit / Debit */}
      <Route path='/credits-list' element={<PrivateRoute element={<CreditList />} isAuthenticated={isAuthenticated} />} />
      <Route path='/credits-work' element={<PrivateRoute element={<CreditWork />} isAuthenticated={isAuthenticated} />} />
      <Route path='/compliments-list' element={<PrivateRoute element={<ComplimentList />} isAuthenticated={isAuthenticated} />} />
      <Route path='/pending-payments' element={<PrivateRoute element={<PendingPayments />} isAuthenticated={isAuthenticated} />} />

      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

function PrivateRoute({ element, isAuthenticated }) {
  return isAuthenticated ? (
    <Routes>
      <Route path='/' element={element} />
    </Routes>
  ) : ""
}

export default User