import React, { useEffect, useState } from 'react'
import './credit-work.scss'
import SinglePage from '../../components/common/page/SinglePage'
import { useSearchParams } from 'react-router-dom'
import { accountingAxios } from '../../config/axios'
import TableFilter from '../../components/common/table-filter/TableFilter'
import NormalInput from '../../components/common/inputs/NormalInput'
import { formatStringDate } from '../../assets/javascript/formate-functions'
import { FaHistory, FaStar } from 'react-icons/fa'
import { PiPlusMinusBold } from 'react-icons/pi'
import { TbUserShare } from 'react-icons/tb'
import CreditSetoffForm from '../../components/user/credit-setoff-form/CreditSetoffForm'
import Modal from '../../components/common/modal/Modal'
import SpinWithMessage from '../../components/common/spinners/SpinWithMessage'
import WorkCreditHistory from '../../components/user/work-credit-history/WorkCreditHistory'

function CreditWork() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState('')
    const [data, setData] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false, })

    useEffect(() => {
        const month = searchParams.get('month');
        const cid = searchParams.get('cid');

        if (month || cid) {
            setLoading('fetch')
            accountingAxios.get(`/credit-work?month=${month || ''}&cid=${cid || ''}`).then((response) => {
                setData(response.data)
                setLoading('')
            })
        } else {
            setSearchParams({ month: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}` })
        }

    }, [searchParams])


    return (
        <div className='credit-work-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={[`Credit works / ${searchParams.get('month') || searchParams.get('cid')}`]}>
                <div className="table-div">
                    {loading === 'fetch' ?
                        <SpinWithMessage message='Loading...' />
                        : <TableFilter srlNo={true} topRight={searchParams.get('month') &&
                            <NormalInput label='Choose date' type={'month'} value={searchParams.get('month')} name='input-date'
                                max={`${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`}
                                onChangeFun={(e) => setSearchParams({ month: e.target.value })} />}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Service No</th>
                                        <th>CID & Name</th>
                                        <th>Contact</th>
                                        <th>Balance</th>
                                        <th>Due date</th>
                                        <th>Technician</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.[0] && data.map((work) => <tr key={work?.service_srl_number}>
                                        <td>{formatStringDate(work?.date)}</td>
                                        <td>{work?.service_srl_number}</td>
                                        <td>CID : {work?.cid}   <small className={`text-badge green-text`}><FaStar /> {work?.star_rate || 0}</small>
                                            <br></br> {work?.first_name}  {work?.last_name}</td>
                                        <td>{work?.contact1?.number} <br></br> {work?.contact2?.number}</td>
                                        <td className='amount-td'>{`₹${work?.balance_amount}` ||
                                            <span className='text-badge Closed-g-text'>Closed</span>}</td>
                                        <td>{work?.payment_history?.[0] && formatStringDate(work?.payment_history[work?.payment_history.length - 1].balance_due_date)}</td>
                                        <td>{work?.technician}</td>
                                        <td>
                                            <div className="buttons">
                                                {work?.payment_history?.[0] && <button className='btn-icon-div bg_gray' title='Payment history' onClick={() => setModal({
                                                    title: `Payment History / ${work?.service_srl_number}`, status: true, width: '500px',
                                                    content: <WorkCreditHistory payment_history={work?.payment_history || []} />
                                                })}><FaHistory /> </button>}
                                                {!searchParams.get('cid') && <button className='btn-icon-div bg_user' title='More credits of this customer'
                                                    onClick={() => setSearchParams({ cid: `${work?.cid}` })}><TbUserShare /></button>}
                                                {searchParams.get('cid') && <button className='btn-icon-div bg_blue' title='Setoff' onClick={() =>
                                                    setModal({
                                                        content: <CreditSetoffForm cid={work?.cid} srl_no={work?.service_srl_number} balance={work?.balance_amount}
                                                            setModal={setModal} setData={setData} full_name={work?.first_name + " " + work?.last_name} />, title: 'Setoff credits', status: true,
                                                    })}><PiPlusMinusBold /></button>}
                                            </div>
                                        </td>
                                        {/* This for Search optimization */}
                                        <td style={{ display: 'none' }}>₹{work?.balance_amount || 'Closed'}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </TableFilter>}
                </div>
            </SinglePage>
        </div>
    )
}

export default CreditWork