import React, { useEffect, useState } from 'react'
import './credit-list.scss'
import SinglePage from '../../components/common/page/SinglePage'
import TableFilter from '../../components/common/table-filter/TableFilter'
import Modal from '../../components/common/modal/Modal'
import CreditForm from '../../components/user/credit-form/CreditForm'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import { accountingAxios } from '../../config/axios'
import { calculateCounts } from '../../assets/javascript/calc-functions'
import { formatAmount, formatPhoneNumber } from '../../assets/javascript/formate-functions'
import { AiOutlinePlus } from 'react-icons/ai'
import { PiPlusMinusBold } from 'react-icons/pi'
import { BsDatabaseFillX, BsFilterCircleFill } from 'react-icons/bs'
import { FaStar } from 'react-icons/fa'
import { IoMdCloseCircle } from "react-icons/io";
import { TbUserShare } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

function CreditList() {
    const [data, setData] = useState([])
    const [catchData, setCatchData] = useState([])
    const [loading, setLoading] = useState('')
    const [total, setTotal] = useState(0)
    const [custCount, setCustCount] = useState(0)
    const [counts, setCounts] = useState({ above_1000: 0, above_5000: 0, above_10000: 0 })
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const navigate = useNavigate()
    const [filter, setFilter] = useState(0)

    const openModal = (content, title) => {
        setModal({ ...modal, content, title, status: true })
    }

    useEffect(() => {
        setCustCount(data.length)
        setCounts(calculateCounts(data))
    }, [data])

    const doFilterList = (value1, value2) => {
        if (filter === value1) {
            setData(catchData)
            setFilter(0)
        } else {
            setData(catchData.filter((obj) => obj?.credit_amount >= value1 && obj?.credit_amount < value2))
            setFilter(value1)
        }
    }

    useEffect(() => {
        if (filter) {

        } else {
            setData(catchData)
        }
    }, [filter])


    useEffect(() => {
        setLoading('fetch')
        accountingAxios.get('/credit-amount').then((response) => {
            setTotal(response.data.total_amount)
            setData(response?.data?.customers)
            setCatchData(response?.data?.customers)
            setLoading('')
        })
    }, [])

    return (
        <div className='credit-list-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Credits list']}>
                {data?.[0] ?
                    <>
                        <div className="credits-top">
                            <div className="left">
                                <div className="count-card">
                                    <h3>₹{formatAmount(total)}</h3>
                                    <p>Total Amount</p>
                                </div>
                            </div>
                            <div className="right">
                                <div className="count-card">
                                    <h3 >{custCount}</h3>
                                    <p>No of Customers</p>
                                </div>
                                <div className="count-card">
                                    <h3 style={{ color: 'rgb(0, 143, 88)' }}>{counts.above_1000}
                                        <span onClick={() => doFilterList(1000, 5000)}>{filter === 1000 ? <IoMdCloseCircle /> : <BsFilterCircleFill />}</span>
                                    </h3>
                                    <p>Above 1,000</p>
                                </div>
                                <div className="count-card">
                                    <h3 style={{ color: 'rgb(190, 108, 0)' }}>{counts.above_5000}
                                        <span onClick={() => doFilterList(5000, 10000)}>{filter === 5000 ? <IoMdCloseCircle /> : <BsFilterCircleFill />}</span>
                                    </h3>
                                    <p>Above 5,000</p>
                                </div>
                                <div className="count-card">
                                    <h3 style={{ color: 'rgb(212, 50, 0)' }}>{counts.above_10000}
                                        <span onClick={() => doFilterList(10000, Infinity)}>{filter === 10000 ? <IoMdCloseCircle /> : <BsFilterCircleFill />}</span>
                                    </h3>
                                    <p>Above 10,000</p>
                                </div>
                            </div>
                        </div >
                        <div className="table-div">
                            <TableFilter srlNo={true} topRight={<button className='add-button' title='Create now'
                                onClick={() => openModal(<CreditForm setTotal={setTotal} setModal={setModal} setData={setData} />, 'Create a credit')}>
                                <AiOutlinePlus /> Create</button>}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CID</th>
                                            <th>Full name</th>
                                            <th>Contact</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((customer) => {
                                            return <tr key={customer.cid}>
                                                <td>{customer.cid}</td>
                                                <td><span>{customer.first_name} {customer.last_name}
                                                    <small className={`text-badge green-text`}><FaStar /> {customer?.star_rate || 0}</small>
                                                </span><br></br>{customer?.address?.address}</td>
                                                <td>
                                                    {formatPhoneNumber(customer?.contact1?.number || '')}<br></br>
                                                    {formatPhoneNumber(customer?.contact2?.number || '')}<br></br>
                                                </td>
                                                <td style={{ color: customer.credit_amount > 0 ? 'black' : 'red' }}>₹{customer.credit_amount}</td>
                                                <td> <div className="buttons">
                                                    <button className='btn-icon-div bg_user' title='More credits of this customer'
                                                        onClick={() => navigate(`/credits-work?cid=${customer?.cid}`)}><TbUserShare /></button>
                                                    <button className='btn-icon-div bg_blue' title='Tally'
                                                        onClick={() => openModal(<CreditForm setTotal={setTotal} setModal={setModal} data={customer} setData={setData} />,
                                                            'Tally credit')}><PiPlusMinusBold /></button>
                                                </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </TableFilter>
                        </div>
                    </>
                    : <>
                        <Spinner icon={loading !== 'fetch' && <BsDatabaseFillX />} message={loading === 'fetch' ? 'Fetch data...' : 'No credits'} spin={loading}
                            bottomContent={loading !== 'fetch' && <div className='add-buttons-div'>

                                <button className='add-button'
                                    onClick={() => openModal(<CreditForm setTotal={setTotal} setModal={setModal} setData={setData} />, 'Create a credit')}>
                                    <AiOutlinePlus /> Create</button>
                            </div>} />
                    </>
                }
            </SinglePage >
        </div >
    )
}

export default CreditList