import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatAmount, numberInWord } from '../../../../assets/javascript/formate-functions'

function Receipt({ data, totalConfirmAmount, receipt, sspRenewal }) {
    const styles = StyleSheet.create({

        table: {
            display: 'table',
            width: '100%',
            marginTop: '5px',
            borderColor: '#ededed',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableRow: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            borderBottomStyle: 'dashed',
            borderBottomColor: '#b8b8b8',
            borderBottomWidth: 1
        },
        tableCell: {
            flex: 1,
            fontSize: '11px',
            padding: 5,
            textAlign: 'start',
        },
        totalRow: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            borderBottomStyle: 'dashed',
            borderBottomColor: '#b8b8b8',
            borderBottomWidth: 1
        },
        totalCell: {
            backgroundColor: '#cccccc',
            flex: 1,
            fontSize: '12px',
            fontWeight: 700,
            padding: 5,
            textAlign: 'start',
        },
        text: {
            fontSize: '14px',
            fontWeight: 600,
            textAlign: 'center',
            marginTop: '20px'
        }
    })

    return (
        <View>
            <View style={styles.table}>

                {data?.bill_data?.ssp_fund && <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>SSP Fund</Text>
                    <Text style={styles.tableCell}>₹{data?.bill_data?.ssp_fund + (data?.bill_data?.primary_spare_total_receivable || 0)}</Text>
                </View>}

                {data?.bill_data?.amc_fund && <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>AMC Fund</Text>
                    <Text style={styles.tableCell}>₹{data?.bill_data?.amc_fund}</Text>
                </View>}

                <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Primary spare amount</Text>
                    <Text style={styles.tableCell}>₹{sspRenewal ? 0 : (data?.bill_data?.primary_spare_total_receivable || 0)}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Secondary spare amount</Text>
                    <Text style={styles.tableCell}>₹{data?.bill_data?.secondary_spare_total_receivable || 0}</Text>
                </View>

                {Number(data?.bill_data?.special_work_spare_total_est) && <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Special work spare amount</Text>
                    <Text style={styles.tableCell}>₹{data?.bill_data?.special_work_spare_total_est || 0}</Text>
                </View>}

                <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Service charge</Text>
                    <Text style={styles.tableCell}>{data?.bill_data?.service_charge_receivable ? `₹${data?.bill_data?.service_charge_receivable}` : '₹0'}</Text>
                </View>
                {data?.bill_data?.extra_charge_receivable ? <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Extra service charge</Text>
                    <Text style={styles.tableCell}>₹{data?.bill_data?.extra_charge_receivable}</Text>
                </View> : ''}

                {data?.bill_data?.compliment_given_now && <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Compliment given</Text>
                    <Text style={styles.tableCell}>-₹{data?.bill_data?.compliment_given_now}</Text>
                </View>}

                <View style={styles.totalRow}>
                    <Text style={styles.totalCell}>Total</Text>
                    <Text style={styles.totalCell}>₹{formatAmount(data?.bill_data?.grand_total_receivable)}</Text>
                </View>
            </View>

            {receipt && <>
                <View style={styles.table}>
                    {totalConfirmAmount ?
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Received amount</Text>
                            <Text style={styles.tableCell}>₹{formatAmount(totalConfirmAmount)}</Text>
                        </View>
                        : ''}
                    {totalConfirmAmount ?
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Received amount In Words</Text>
                            <Text style={styles.tableCell}>{numberInWord(parseInt(totalConfirmAmount))} rupees only</Text>
                        </View>
                        : ''}
                    {data?.balance_amount ?
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Pending amount</Text>
                            <Text style={styles.tableCell}>₹{formatAmount(data?.balance_amount + (data?.total_amount_received - totalConfirmAmount))}</Text>
                        </View>
                        : ''}
                </View>
                <Text style={styles.text}>Thank you for your business!</Text>
            </>}
        </View >
    )
}

export default Receipt