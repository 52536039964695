import React, { useEffect, useRef, useState } from 'react'
import * as XLSX from 'xlsx';
import './import-ledger.scss'
import ScreenShort from '../../../assets/images/customer-ledger.png'
import { toast } from 'react-hot-toast'
import { accountingAxios } from '../../../config/axios';

function ImportLedger({ setModal, setData }) {
    const inputRef = useRef(null)
    const [text, setText] = useState('Choose file')
    const [jsonData, setJsonData] = useState(null);
    const [track, setTrack] = useState(null)
    const [loading, setLoading] = useState(true);


    const handleFileInput = () => {
        if (text === 'Choose file') {
            inputRef.current.click()
        } else {
            toast.error('wait...')
        }
    }

    const handleImageLoad = () => {
        setLoading(false);
        // setError(false);
    };

    const handleChangeFile = (e) => {
        const file = e.target.files[0];

        if (!file) {
            toast.error('Choose any file!')
            return;
        }

        const allowedExtensions = ['.xls', '.xlsx'];
        const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
        if (!allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
            toast.error('Please upload a valid Excel file')
            return;
        }

        setText('Converting...')

        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0]; // Assuming you want to convert the first sheet
            const sheet = workbook.Sheets[sheetName];

            const jsonResult = XLSX.utils.sheet_to_json(sheet);
            setJsonData(jsonResult.map((ledger) => ({ ...ledger, category: "Customer" })))

        };

        reader.readAsArrayBuffer(file);
    }

    useEffect(() => {
        if (jsonData) {
            if (jsonData[0]) {
                setText('Uploading...')
                accountingAxios.post('/ledgers/customer/import', { jsonData }).then((response) => {
                    setData((state) => [...response.data.uploaded, ...state])
                    setTrack(response.data.track)
                    setText('Choose file')
                }).catch((error) => {
                    setText('Choose file')
                    toast.error(error.message)
                    setModal({ status: false })
                })
            } else {
                toast.error('No data to upload !')
                setText('Choose file')
            }
        }
        // eslint-disable-next-line
    }, [jsonData])





    return (
        <div className='import-ledger-div'>
            {track
                ? <div className='track-div'>
                    <div className="top-div">
                        <div className="count-div">
                            <h2>{track.successCount || 0}</h2>
                            <p>Uploaded</p>
                        </div>
                        <div className="count-div">
                            <h2>{track.duplicateCount || 0}</h2>
                            <p>Duplicate</p>
                        </div>
                        <div className="count-div">
                            <h2>{track.errorCount || 0}</h2>
                            <p>Invalid</p>
                        </div>
                    </div>
                    {track.duplicateCid?.[0] && <div className="list-div">
                        <p>Duplicate Customer ID</p>
                        <p> {track.duplicateCid.map((cid) => <span>{`${cid}, `}</span>)}</p>
                    </div>}

                </div>
                : <div className="import-border">
                    <div className="image-div">
                    {loading && <p>Loading image...</p>}
                        <img src={ScreenShort} onLoad={handleImageLoad}  alt="Customer Ledger Screenshot" />
                    </div>
                    <div className="info-div">
                        <ol>
                            <li>Arrange the data as shown in the image ^</li>
                            <li>Enter title as <span dangerouslySetInnerHTML={{ __html: "<b> No, cid, name, customers and commission </b>" }}></span> respectively.</li>
                            <li>Only the first sheet of the excel file will be imported.</li>
                            <li>Please wait few seconds for complete upload</li>
                        </ol>
                    </div>
                    <div className="import-button-div">
                        <input type="file" ref={inputRef} name='file' hidden onChange={handleChangeFile} accept=".xls, .xlsx" />
                        <button onClick={handleFileInput}>{text}</button>
                        <p>{text === 'Choose file' ? '* Choose xl file only' : 'Please wait..'}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default ImportLedger