import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    markService: [],
}

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {

        enteredToTally: (status, action) => {
            if (status.markService.includes(action.payload)) {
                status.markService = status.markService.filter((value) => value !== action.payload)
            } else {
                status.markService = [
                    ...status.markService,
                    action.payload
                ]
            }
        }

    }
})


export const { reset, enteredToTally } = serviceSlice.actions;
export default serviceSlice.reducer