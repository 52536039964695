import React, { useState } from 'react'
import NormalInput from '../../common/inputs/NormalInput'
import './add-edit-technician-ledger.scss'
import { BiLoaderAlt } from 'react-icons/bi'
import { accountingAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'

function AddEditTechnicianLedger({ data, setData, setModal }) {
    const [form, setForm] = useState(data || {})
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if (data) {
            accountingAxios.put('/ledgers/technician', form).then(() => {
                setData((state) => state.map((item) => {
                    if (item._id === form._id) {
                        return form
                    }
                    return item
                }))
                setModal((state) => ({ ...state, status: false }))
                toast.success('Ledger Updated')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        } else {
            accountingAxios.post('/ledgers/technician', form).then((response) => {
                setData((state) => [response.data, ...state])
                setModal((state) => ({ ...state, status: false }))
                toast.success('Ledger Created')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        }
    }

    return (
        <div className='add-edit-customer'>
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='Name' name='name' value={form?.name || ''} onChangeFun={handleChange} />
                <NormalInput label='AMC Fund Ledger' name='amc_fund' value={form?.amc_fund || ''} onChangeFun={handleChange} />
                <NormalInput label='SSP Fund Ledger' name='ssp_fund' value={form?.ssp_fund || ''} onChangeFun={handleChange} />
                <NormalInput label='Field collection Ledger' name='field_collection' value={form?.field_collection || ''} onChangeFun={handleChange} />
                <NormalInput label='Spares sales' name='spare_sales' value={form?.spare_sales || ''} onChangeFun={handleChange} />
                <NormalInput label='Service Charge Ledger' name='service_charge' value={form?.service_charge || ''} onChangeFun={handleChange} />
                <NormalInput label='Godown Ledger' name='godown' value={form?.godown || ''} onChangeFun={handleChange} />

                <div className="button-div">
                    <button>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : data ? 'Update' : 'Create'}</button>
                </div>
            </form>
        </div>
    )
}

export default AddEditTechnicianLedger