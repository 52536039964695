import React from 'react';
import './work-type-spares.scss';
import { FaRegDotCircle, FaInfoCircle } from 'react-icons/fa'

function WorkTypeSpares({ type, data, total }) {

    return (
        <div className="work-type-spares-div">
            <div className="card-div">
                <div className="head-div">
                    <div className="left">
                        <FaRegDotCircle />
                        <h4>{type} estimate</h4>
                    </div>
                    <div className="right">

                    </div>
                </div>
                <div className="content-div">
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Spare name</th>
                                <th>MRP</th>
                                {data?.[0]?.rate === 0 ? <>
                                    <th>Rate</th>
                                </> : data?.[0]?.mrp !== data?.[0]?.rate
                                    ? <>
                                        <th>Less(%)</th>
                                        <th>SSP rate</th>
                                    </> : <></>}
                                <th>Qty</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((spare, index) => <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{spare.spare_name}</td>
                                <td>₹{spare.mrp}</td>
                                {data?.[0]?.rate === 0 ? <>
                                    <td>₹{spare.rate || '0'}</td>
                                </> : data?.[0]?.mrp !== data?.[0]?.rate
                                    ? <>
                                        <td>₹{spare.discount_percentage || '-'}%</td>
                                        <td>₹{spare.rate || '0'}</td>
                                    </> : <></>}
                                <td>{spare.quantity}</td>
                                <td>₹{Number(spare.rate) * Number(spare.quantity) || 0} {spare?.warranty && '(Warr)'}</td>
                            </tr>)}

                            <tr className='bold-text underline-bold'>
                                <td></td>
                                <td>Total</td>
                                <td></td>
                                {data?.[0]?.rate === 0 ? <>
                                    <td></td>
                                </> : data?.[0]?.mrp !== data?.[0]?.rate
                                    ? <>
                                        <td></td>
                                        <td></td>
                                    </> : <></>}
                                <td></td>
                                <td>₹{total || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default WorkTypeSpares