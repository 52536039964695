import React, { useEffect, useState } from 'react'
import './credit-setoff-form.scss'
import NormalInput from '../../common/inputs/NormalInput'
import SelectInput from '../../common/inputs/SelectInput'
import { BiLoaderAlt } from 'react-icons/bi'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import { accountingAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'

function CreditSetoffForm({ cid, full_name, srl_no, balance, setModal, setData }) {
  const [form, setForm] = useState({ srl_no, cid, full_name, received_date: YYYYMMDDFormat(new Date()) })
  const [loading, setLoading] = useState(false)
  const receivedMethod = [
    { option: 'CASH', value: 'CASH' },
    { option: 'BANK', value: 'BANK' },
    { option: 'CASH-AND-BANK', value: 'CASH-AND-BANK' },
    { option: 'CHEQUE', value: 'CHEQUE' }
  ]

  const handleChange = (e) => {

    if (e.target.name === 'received_method') {
      setForm({
        ...form,
        cash: undefined,
        bank: undefined,
        cheque: undefined,
        balance_due_date: undefined,
        reason: undefined,
        [e.target.name]: e.target.value
      })
    } else if (e.target.name === 'cash' || e.target.name === 'bank' || e.target.name === 'cheque') {
      setForm({
        ...form,
        balance_due_date: undefined,
        reason: undefined,
        [e.target.name]: e.target.value
      })

    } else if (e.target.name === 'reason') {
      setForm({
        ...form,
        balance_due_date: YYYYMMDDFormat(new Date(new Date(form?.received_date).setDate(new Date(form?.received_date).getDate() + 15))),
        [e.target.name]: e.target.value
      })
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if ((Number(form?.cash || 0) + Number(form?.bank || 0) + Number(form?.cheque || 0)) > balance) {
      toast.error('Negative balance')
      return;
    }

    setLoading(true)

    accountingAxios.put('/credit-work', form).then((response) => {
      setLoading(false)
      setModal({ status: false })
      if (Number(balance - (Number(form?.cash || 0) + Number(form?.bank || 0) + Number(form?.cheque || 0)))) {

      }
      setData((state) => state.map((work) => {
        if (work.service_srl_number === srl_no) {
          return {
            ...work,
            balance_amount: response.data?.balance_amount || 0,
            payment_history: response.data?.payment_history || []
          }
        } else {
          return work
        }
      }))
      toast.success('Success')
    }).catch((error) => {
      toast.error(error.message)
      setLoading(false)
    })
  }


  return (
    <div className='credit-form-div'>
      <form action="" onSubmit={handleSubmit}>
        <div className="amount-div">
          <h4>{Number(balance - (Number(form?.cash || 0) + Number(form?.bank || 0) + Number(form?.cheque || 0)))}</h4>
          <p>Balance</p>
        </div>
        <NormalInput label='Date' name='received_date' value={form?.received_date} type={'date'} max={YYYYMMDDFormat(new Date())} onChangeFun={handleChange} />
        <SelectInput label='Received method' name='received_method' firstOption={{ option: 'Choose...', value: "" }} values={receivedMethod} onChangeFun={handleChange} />

        <div className="two-input-single-line-div">
          {(form?.received_method === 'CASH-AND-BANK' || form?.received_method === 'CASH') &&
            <NormalInput label='As cash' type={'number'} name='cash' value={form?.cash || ''} min={0} onChangeFun={handleChange} />}
          {(form?.received_method === 'BANK' || form?.received_method === 'CASH-AND-BANK') &&
            <NormalInput label='To bank' type={'number'} name='bank' value={form?.bank || ''} min={1} onChangeFun={handleChange} />}
          {(form?.received_method === 'CHEQUE') &&
            <NormalInput label='As Cheque' type={'number'} name='cheque' value={form?.cheque || ''} min={1} onChangeFun={handleChange} />}
        </div>

        {balance > (Number(form?.cash || 0) + Number(form?.bank || 0) + Number(form?.cheque || 0)) && <>
          <NormalInput label='Balance will receive on / Before' name='balance_due_date'
            value={YYYYMMDDFormat(new Date(new Date(form?.received_date).setDate(new Date(form?.received_date).getDate() + 15)))} type={'date'} />
          <NormalInput label='Reason for credit' name='reason' value={form.reason} onChangeFun={handleChange} />
        </>}

        <div className="button-div">
          <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Submit'}</button>
        </div>
      </form>
    </div>
  )
}

export default CreditSetoffForm