import React from 'react'
import { View, Document } from '@react-pdf/renderer';
import PageWrapper from './components/PageWrapper';
import './Fonts'
import ReceiptInfo from './components/ReceiptInfo';
import Receipt from './components/Receipt';
import BottomSeal from './components/BottomSeal'


function ReceiptPdf({ data, totalConfirmAmount }) {

    return (
        <Document>
            <PageWrapper receipt={true}>
                <View>
                    {/* Info */}
                    <ReceiptInfo data={data} />

                    {/* Receipt */}
                    <Receipt data={data} totalConfirmAmount={totalConfirmAmount} receipt={true}
                        sspRenewal={data?.service_data?.package_renewal && data?.service_data?.new_customer_status === 'SSP'} />

                    {/* Seal */}
                    <BottomSeal data={data} left={true} />
                </View>
            </PageWrapper>
        </Document>
    )
}

export default ReceiptPdf