import React, { useState, useEffect } from 'react'
import './compliment-form.scss'
import NormalInput from '../../common/inputs/NormalInput'
import { BiLoaderAlt } from 'react-icons/bi'
import { accountingAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'

function ComplimentFrom({ data, setData, setModal, setTotal }) {
    const [form, setForm] = useState({ cid: '', amount: 0 })
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(data?.debit_amount || 0)

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'amount') {
            setAmount((data?.debit_amount || 0) + Number(e.target.value))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if (data) {
            accountingAxios.put('/compliment-amount', form).then(() => {
                if (amount > 0) {
                    setData((state) => state.map((item) => {
                        if (item.cid === form.cid) {
                            return { ...item, debit_amount: Number(amount) }
                        } else {
                            return item
                        }
                    }))
                } else {
                    setData((state) => state.filter((item) => item.cid !== form.cid))
                }
                setTotal((currentTotal) => Number(currentTotal) + Number(form.amount))
                setModal((state) => ({ ...state, status: false }))
                toast.success('Compliment Updated')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        } else {
            accountingAxios.post('/compliment-amount', form).then((response) => {
                setData((state) => [response.data, ...state])
                setTotal((currentTotal) => Number(currentTotal) + Number(amount))
                setModal((state) => ({ ...state, status: false }))
                toast.success('Compliment created')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if (data) {
            setForm({
                ...form,
                cid: data.cid
            })
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <div className='compliment-form-div'>
            <form action="" onSubmit={handleSubmit}>
                {data && <div className="amount-div">
                    <h4>{String(amount) || data?.debit_amount || 0}</h4>
                    <p>Balance</p>
                </div>}
                {!data && <NormalInput label='Customer ID' type={'number'} name='cid' value={form?.cid || ''} onChangeFun={handleChange} />}
                <NormalInput label={data ? 'Amount (-/+)' : 'Compliment'} type={'number'} name='amount' value={form?.amount || ''} onChangeFun={handleChange} />
                <div className="button-div">
                    <button>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : data ? 'Update' : 'Create'}</button>
                </div>
            </form>
        </div>
    )
}

export default ComplimentFrom