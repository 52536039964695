import axios from 'axios'
export const baseIP = 'localhost'

const baseSetup = {
    authAxios: axios.create({
        baseURL: `https://api.accounting.alliancewatersolutions.com/auth/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    accountingAxios: axios.create({
        baseURL: `https://api.accounting.alliancewatersolutions.com/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    purifierAxios: axios.create({
        baseURL: `https://api.purifier.alliancewatersolutions.com/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    customerAxios: axios.create({
        baseURL: `https://api.controlnex.alliancewatersolutions.com/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    staffAxios: axios.create({
        baseURL: `https://api.staff.alliancewatersolutions.com/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    wholeAxios: axios.create({
        baseURL: `https://api.wholehouse.alliancewatersolutions.com/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),

}


const handleTokenError = () => {
    // Redirect the user to the login page or perform any other necessary action
    window.location.href = `https://www.staff.alliancewatersolutions.com/`
}

const requestConfigFunction = (config) => {
    let userToken = localStorage.getItem('_tkn_stf')
    if (userToken) {
        config.headers['Authorization'] = `Bearer ${userToken}`;
    }
    return config
}

const requestErrorFunction = (error) => {
    return Promise.reject(error);
}

const responseConfigFunction = (response) => {
    // Handle successful responses here if needed
    return response.data;
}

const responseErrorFunction = (error) => {
    if (error.response && error.response.status === 401) {
        handleTokenError();
    }
    return Promise.reject(error.response.data);
}


// Add an interceptor to authAxios for request
baseSetup.authAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to authAxios for response errors
baseSetup.authAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to accountingAxios for request
baseSetup.accountingAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to accountingAxios for response errors
baseSetup.accountingAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to purifierAxios for request
baseSetup.purifierAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to purifierAxios for response errors
baseSetup.purifierAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to customerAxios for request
baseSetup.customerAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to customerAxios for response errors
baseSetup.customerAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to staffAxios for request
baseSetup.staffAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to staffAxios for response errors
baseSetup.staffAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to wholeAxios for request
baseSetup.wholeAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to wholeAxios for response errors
baseSetup.wholeAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);


export const { authAxios, accountingAxios, purifierAxios, customerAxios, staffAxios, wholeAxios } = baseSetup


