import React, { useEffect, useState } from 'react'
import './purifier-service-list.scss'
import SinglePage from '../../components/common/page/SinglePage'
import TableFilter from '../../components/common/table-filter/TableFilter'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsArrowsFullscreen } from 'react-icons/bs'
import { workTypeFormatChange, YYYYMMDDFormat } from '../../assets/javascript/formate-functions'
import { enteredToTally } from '../../redux/features/serviceSlice'
import toast from 'react-hot-toast'
import { accountingAxios } from '../../config/axios'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import { FiRefreshCw } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

function PurifierServiceList() {
    const location = useLocation()
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState('')
    const [today, setToday] = useState(false)
    const { markService } = useSelector((state) => state.service)

    const handleEnteredToTally = (srl_number) => {
        dispatch(enteredToTally(srl_number))
    }

    const fetchData = (from, to, cid) => {
        setLoading('fetch')
        accountingAxios.get(`/purifier-service-list/?from_date=${from}&to_date=${to}&cid=${cid}`).then((response) => {
            setData(response.data)
            setLoading('')
        }).catch(() => {
            setLoading('')
            toast.error('Try again!')
            navigate('/')
        })
    }

    useEffect(() => {
        if (location?.state?.from_date === location?.state?.to_date && location?.state?.from_date === YYYYMMDDFormat(new Date())) {
            setToday(true)
        }
        if (!location?.state?.from_date || !location?.state?.to_date) {
            navigate('/')
        } else {
            fetchData(location?.state?.from_date, location?.state?.to_date, location?.state?.cid)
        }
        // eslint-disable-next-line
    }, [])


    return (
        <div className='purifier-service-list-div'>
            <SinglePage titleArray={['Purifier service list']}>
                {loading === 'fetch' ?
                    <>
                        <Spinner message='Fetch data...' />
                    </>
                    : <>
                        <div className="service-list-top">
                            <div className="left">
                                {location?.state?.from_date === location?.state?.to_date
                                    ? <>
                                        <p>Date : {location?.state?.from_date}</p>
                                    </>
                                    : <>
                                        <p>From : {location?.state?.from_date}</p>
                                        <p>To : {location?.state?.to_date}</p>
                                    </>}
                            </div>
                        </div>
                        <div className="table-div">
                            <TableFilter srlNo={true} topRight={today && <button onClick={() => fetchData(location?.state?.from_date, location?.state?.to_date, location?.state?.cid)}
                                className='refresh' title='Refresh data'><FiRefreshCw /></button>}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Service No</th>
                                            <th>CID & Name</th>
                                            {/* <th>Work</th> */}
                                            <th>Work type</th>
                                            <th>Technician <br></br>name</th>
                                            {/* <th>Get <br></br>to tally</th> */}
                                            <th>Entered <br></br>to tally</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {data?.[0] ?
                                        <tbody>
                                            {data.map((service) => <tr key={service.service_srl_number}>
                                                <td>{service.date}</td>
                                                <td>{service.service_srl_number}</td>
                                                <td>{service.cid} <span title='on status' className={` ${service.purifier_customer_status}-text text-badge`}>
                                                    {service.purifier_customer_status}</span>  <br></br>{service.full_name}</td>
                                                {/* <td>{service.work} </td> */}
                                                <td>{workTypeFormatChange(service.work_type)}</td>
                                                <td>{service.name_of_technician}</td>
                                                {/* <td>{service.take_to_tally
                                                    ? <span className='text-badge green-text'>Yes</span>
                                                    : <span className='text-badge no-text'>No</span>}</td> */}
                                                <td><input type='checkbox' checked={markService?.includes(service.service_srl_number)} onClick={() => handleEnteredToTally(service.service_srl_number)} /></td>
                                                <td>
                                                    <div className="buttons">
                                                        <button className='btn-icon-div bg_gray' title='View'
                                                            onClick={() => navigate(`/purifier/services-list/${service.service_srl_number}`)}><BsArrowsFullscreen /></button>
                                                    </div>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                        : ''}
                                </table>
                            </TableFilter>
                        </div>
                    </>}
            </SinglePage>
        </div>
    )
}

export default PurifierServiceList