import React, { useEffect, useState } from 'react'
import './technician-ledgers.scss'
import SinglePage from '../../components/common/page/SinglePage'
import TableFilter from '../../components/common/table-filter/TableFilter'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import Modal from '../../components/common/modal/Modal'
import AddEditTechnicianLedger from '../../components/user/technician-ledger-form/AddEditTechnicianLedger'
import { accountingAxios } from '../../config/axios'
import { toast } from 'react-hot-toast'
import { BsDatabaseFillX, BsTrash3Fill } from 'react-icons/bs'
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiLoaderAlt } from 'react-icons/bi'

function TechnicianLedgers() {
    const [loading, setLoading] = useState('')
    const [data, setData] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false })

    const openModal = (content, title) => {
        setModal({ ...modal, content, title, status: true })
    }

    const handleDelete = (_id) => {
        const ask = window.confirm('Are you delete this ledger from XML ?')
        if (ask) {
            setLoading('delete' + _id)
            accountingAxios.delete(`/ledgers/technician?_id=${_id}`).then(() => {
                setData((state) => state.filter((item) => item._id !== _id))
                setLoading('')
            })
        }
    }

    useEffect(() => {
        setLoading('get-data')
        accountingAxios.get('/ledgers/technician').then((response) => {
            setData(response.data)
            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
        })
    }, [])
    return (
        <div className='technician-ledger-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Technician ledgers']}>
                {data[0] ?
                    <TableFilter srlNo={true} topRight={<button className='add-button' title='Create new'
                        onClick={() => openModal(<AddEditTechnicianLedger setModal={setModal} setData={setData} />, 'Create Ledger')}>
                        <AiOutlinePlus /> Create</button>} >
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>AMC Fund</th>
                                    <th>SSP Fund</th>
                                    <th>Field collection</th>
                                    <th>Service charge</th>
                                    <th>Spares sales</th>
                                    <th>Godown</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((technician, index) => (
                                    <tr key={index}>
                                        <td>{technician.name}</td>
                                        <td>{technician.amc_fund}</td>
                                        <td>{technician.ssp_fund}</td>
                                        <td>{technician.field_collection}</td>
                                        <td>{technician.service_charge}</td>
                                        <td>{technician.spare_sales}</td>
                                        <td>{technician.godown}</td>
                                        <td>
                                            <div className="buttons">
                                                <button className='btn-icon-div bg_blue' title='Edit'
                                                    onClick={() => openModal(<AddEditTechnicianLedger setModal={setModal} data={technician} setData={setData} />,
                                                        'Update Ledger')}><FiEdit2 /></button>
                                                <button className='btn-icon-div bg_red' title='Delete' onClick={() => handleDelete(technician._id)}>
                                                    {loading === ('delete' + technician._id) ? <span className='loading-icon'><BiLoaderAlt /></span> : <BsTrash3Fill />}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </TableFilter>
                    : <>
                        <Spinner icon={!loading && <BsDatabaseFillX />} message={loading ? 'Fetch data...' : 'No data'} spin={loading}
                            bottomContent={!loading && <button className='add-button'
                                onClick={() => openModal(<AddEditTechnicianLedger setModal={setModal} setData={setData} />, 'Create Ledger')}>
                                <AiOutlinePlus /> Create New</button>} />
                    </>}
            </SinglePage>
        </div>
    )
}

export default TechnicianLedgers