import { serviceCharges } from './pre-data'

const calculateCounts = (array) => {
    const counts = {
        above_1000: 0, above_5000: 0, above_10000: 0
    }

    for (let i = 0; i < array.length; i++) {
        if (array[i].credit_amount >= 1000 && array[i].credit_amount < 5000) {
            counts.above_1000++
        } else if (array[i].credit_amount >= 5000 && array[i].credit_amount < 10000) {
            counts.above_5000++
        } else if (array[i].credit_amount >= 10000) {
            counts.above_10000++
        }
    }

    return counts
}

const calculateServiceCharge = (work_method, normalCharge) => {
    if (work_method === 'ssp_package_renewal') {
        return serviceCharges.ssp_renewal

    } else if (work_method === 'amc_package_renewal') {
        return serviceCharges.amc_renewal

    } else {
        return normalCharge
    }
}

const calculateTotalAmount = (items, rate_type) => {
    let totalAmount = 0

    items.forEach((item) => {
        totalAmount += item[rate_type] * item.quantity;
    });

    return totalAmount
}

const sspItemsPriceRounding = (items, roundAmount, itemTotalAmount) => {
    const difference = itemTotalAmount - roundAmount
    const totalQuantity = items.reduce((total, item) => total + item.quantity, 0);

    if (difference !== 0 && totalQuantity !== 0) {
        const adjustment = parseInt(difference / totalQuantity);
        const balance = difference - (adjustment * totalQuantity)
        items.forEach((item, index) => {
            item.ssp_rate -= adjustment + (index === 0 ? balance : 0)
        });
    }

    return items;
}

export { calculateCounts, calculateServiceCharge, calculateTotalAmount, sspItemsPriceRounding }