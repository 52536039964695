import React, { useEffect, useState } from 'react'
import './style.scss'
import SinglePage from '../../components/common/page/SinglePage'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import TableFilter from '../../components/common/table-filter/TableFilter'
import { accountingAxios } from '../../config/axios'
import { toast } from 'react-hot-toast'
import { BsDatabaseFillX } from 'react-icons/bs'
import { YYYYMMDDFormat } from '../../assets/javascript/formate-functions'
import { FaCheck } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";

const PendingPayments = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState('fetch')

  useEffect(() => {
    accountingAxios.get('/pending-payment/list').then((response) => {
      setData(response.data)
      setLoading('')
    }).catch((error) => {
      setLoading('')
      toast.error(error.message)
    })
  }, [])


  const handleConfirm = (pay_id) => {

    const ask = window.confirm('Are you confirm this payment ?')

    if (ask) {
      setLoading(`c-${pay_id}`)
      accountingAxios.get(`/pending-payment/confirm?pay_id=${pay_id}`).then(() => {
        setData((state) => state.filter((pay) => pay._id !== pay_id))
        toast.success('Payment Confirmed')
        setLoading('')
      }).catch((error) => {
        toast.error(error.message)
        setLoading('')
      })

    }

  }

  const handleReject = (pay_id) => {

    const ask = window.confirm('Are you reject this payment ?')
    if (ask) {
      setLoading(`r-${pay_id}`)
      accountingAxios.get(`/pending-payment/reject?pay_id=${pay_id}`).then(() => {
        setData((state) => state.filter((pay) => pay._id !== pay_id))
        toast.success('Payment Rejected')
        setLoading('')
      }).catch((error) => {
        toast.error(error.message)
        setLoading('')
      })
    }
  }


  return (
    <div className="pending-payments-div">
      <SinglePage titleArray={['Pending payments']}>
        {data?.[0]
          ? <>
            <TableFilter srlNo={true}>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Who is.</th>
                    <th>Type</th>
                    <th>Total Amount</th>
                    <th>Method</th>
                    <th>Tran_ID / Cheque No</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((payment, index) => {
                    return <tr key={index}>
                      <td>{YYYYMMDDFormat(new Date(payment.date))}</td>
                      <td>{payment.payment_by_id} / {payment.payment_by_name} <br></br>
                        {payment.for_track}</td>
                      <td title={payment.type === 'purifier_service_amount' ? "purifier_service_amount" : payment.type}>
                        {payment.type === 'purifier_service_amount' ? "PSA" : payment.type || 'NILL'}</td>
                      <td>₹{payment.method === 'CASH-AND-BANK' ? payment.bank : payment.total_amount}</td>
                      <td>{payment.method}</td>
                      <td>{payment.tran_track_no}</td>
                      <td>
                        <div className="buttons">
                          <button className='btn-icon-div bg_green' onClick={() => handleConfirm(payment._id)}>{loading === `c-${payment._id}` ? <BsThreeDots /> : < FaCheck />}</button>
                          <button className='btn-icon-div bg_red' onClick={() => handleReject(payment._id)}>{loading === `r-${payment._id}` ? <BsThreeDots /> : <AiOutlineClose />}</button>
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </TableFilter>
          </>
          : <>
            <Spinner icon={loading !== 'fetch' && <BsDatabaseFillX />} message={loading === 'fetch' ? 'Fetch data...' : 'No Pending payments'} spin={loading} />
          </>}
      </SinglePage>
    </div >
  )
}

export default PendingPayments