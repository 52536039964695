import React, { useState } from 'react'
import './xml-download.scss'
import SinglePage from '../../components/common/page/SinglePage'
import NormalInput from '../../components/common/inputs/NormalInput'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import toast from 'react-hot-toast'
import { accountingAxios } from '../../config/axios'
import { IoIosCloseCircle } from 'react-icons/io'
import { MdTipsAndUpdates } from 'react-icons/md'


function XmlDownload() {
    const [report, setReport] = useState('')
    const [xml, setXml] = useState('')
    const [form, setForm] = useState('')
    const [list, setList] = useState([])
    const [loading, setLoading] = useState('')

    const handleChange = (e) => {
        setForm(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form && form[0] !== ' ') {
            if (list.includes(form)) {
                toast.error('Already added')
                setForm('')
            } else {
                setList((state) => [...state, form])
                setForm('')
            }
        } else {
            toast.error('Type without space')
        }
    }


    const generateData = (type) => {
        setLoading('generate')
        accountingAxios.post('/xml-convert/service-data', { auto: type === 'auto', list }).then((response) => {
            if (!response.data.report.successCount && !response.data.report.failedCount) {
                toast.error('No data, Try after few times')
                setLoading('')
                setList([])
            } else {
                downloadXmlFile(response.data.xml)
                setReport(response.data.report)
                setXml(response.data.xml)
                setLoading('')
                setList([])
            }
        })
    }

    const downloadXmlFile = (data) => {
        setLoading('download')
        const blob = new Blob([data], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        // Create an anchor element
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';

        // Trigger the download programmatically
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            // For iPhone/iPad devices, use the 'click' event instead of 'download'
            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            link.dispatchEvent(clickEvent);

        } else {
            // For other devices, set the 'download' attribute and click the link
            link.download = `XML_ON_${new Date().toISOString()}.xml`;
            link.click();
        }
        setLoading('')
    }

    return (
        <div className='xml-download-div'>
            <SinglePage>
                {(report || loading === 'generate')
                    ? <div>
                        {loading
                            ? <Spinner message='Generating xml...' bottomContent={<small style={{ color: 'orange', fontWeight: 500 }}>
                                Don't forgot download XML file
                            </small>} />
                            : <div className='result-div'>
                                <div className="buttons-div">
                                    {report.successCount > 0 && <button onClick={() => downloadXmlFile(xml)}>{loading === 'download' ? "Downloading..." : "Download XML"}</button>}
                                    <button onClick={() => setReport('')}>Close</button>
                                </div>
                                <div className="top-div">
                                    <div className="item-div">
                                        <h4>{report?.successCount || 0}</h4>
                                        <p>Success</p>
                                    </div>
                                    <div className="item-div">
                                        <h4>{report?.failedCount || 0}</h4>
                                        <p>Failed</p>
                                    </div>
                                    <div className="item-div">
                                        <h4>{report?.updateCount || 0}</h4>
                                        <p>New ledgers</p>
                                    </div>
                                </div>
                                <div className="info-div">
                                    {report?.failedData?.map((info, index) => <div key={index} className="info-border error-border">
                                        <div className="left-div">
                                            <IoIosCloseCircle />
                                        </div>
                                        <div className="right-div">
                                            <h4>{info.reason || 'No reason'}</h4>
                                            <p>Date : {info.date} | Srl no : {info.service_srl_number} | Cid : {info.cid}</p>
                                        </div>
                                    </div>)}
                                    {report?.updatedData?.map((info, index) => <div key={index} className="info-border update-border">
                                        <div className="left-div">
                                            <MdTipsAndUpdates />
                                        </div>
                                        <div className="right-div">
                                            <h4>{info.reason || 'No reason'}</h4>
                                            <p>Date : {info.date} | Srl no : {info.service_srl_number} | Cid : {info.cid}</p>
                                        </div>
                                    </div>)}
                                </div>
                            </div>}
                    </div>
                    : <div className='input-page-div'>
                        <div className="section-one">
                            <div className="border">
                                <p>Generate XML file</p>
                                <button onClick={() => generateData('auto')}>Auto Generate</button>
                                <p>Last Generated Time : NILL</p>
                            </div>
                        </div>
                        <div className="divider">
                            <p>OR</p>
                        </div>
                        <div className="section-two">
                            <div>
                                <div className="list-div">
                                    <p>Generate using srl numbers</p>
                                    <p>{list.map((value) => `${value}, `)}</p>
                                </div>
                                <div>
                                    <form action="" onSubmit={handleSubmit}>
                                        <NormalInput label='Serial number' name='srl_number' value={form} onChangeFun={handleChange} />
                                        <button type='submit'>Add to list</button>
                                    </form>
                                </div>
                                {list?.[0] && <div>
                                    <button onClick={() => generateData('manual')} className='generate-button'>Generate</button>
                                </div>}

                            </div>
                        </div>
                    </div>
                }
            </SinglePage >
        </div >
    )
}

export default XmlDownload