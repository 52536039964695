import React, { useEffect } from 'react'
import './home.scss'
import SinglePage from '../../components/common/page/SinglePage'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

function Home() {
  const { user } = useSelector((state) => state.userAuth)
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  useEffect(() => {
    const id = searchParams.get('id');
    if (!id && user._id) {
      setSearchParams(`id=${user._id}`)
    }
    // eslint-disable-next-line
  }, [])


  return (
    <div className="home-page-div">
      <SinglePage titleArray={[`Name : ${user.first_name} ${user.last_name}`, `Designation : ${user.designation.designation}`]}>
        <div className="home-border">
          {/* <div className="button-div" >
            <button onClick={() => navigate('/tally')} >TALLY
            </button>
          </div> */}
          <div className="button-div" >
            <button onClick={() => navigate('/purifier')} >PURIFIER
            </button>
          </div>
          <div className="button-div" >
            <button onClick={() => navigate('/whole-house')} >WHOLE HOUSE
            </button>
          </div>
          <div className="button-div" >
            <button onClick={() => navigate('/compliments-list')} >COMPLIMENTS LIST
            </button>
          </div>
          <div className="button-div" >
            <button onClick={() => navigate('/credits-list')} >CREDITS LIST
            </button>
          </div>
          <div className="button-div" >
            <button onClick={() => navigate(`/credits-work?month=${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`)} >CREDITS WORK
            </button>
          </div>
          <div className="button-div" >
            <button onClick={() => navigate('/pending-payments')} >PENDING PAYMENTS
            </button>
          </div>
        </div>
      </SinglePage >
    </div >
  )
}

export default Home