import React from 'react'
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import Seal from '../../../../assets/images/seal.png'
import { formatStringDate } from '../../../../assets/javascript/formate-functions'

function BottomSeal({ data, left }) {
    const styles = StyleSheet.create({
        parent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'relative',
        },
        text: {
            marginBottom: '2px',
            fontSize: '11px',
            fontWeight: 400
        },
        seal: {
            width: '75px',
            position: 'absolute',
            left: left ? "80%" : '45%',
            top: left ? '0px' : '-20px',
            opacity: .9
        }
    })

    return (
        <View style={styles.parent} >
            <View style={{ textAlign: 'left' }}>
                <Text style={styles.text}>Date : {formatStringDate(data.date)}</Text>
                <Text style={styles.text}>Place : Kaipamangalam</Text>
            </View>
            <Image style={styles.seal} src={Seal} />
        </View>
    )
}

export default BottomSeal