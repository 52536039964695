import React from 'react'

function SelectInput({
    label = 'Label',
    name = 'name',
    id,
    onChangeFun,
    colorCode,
    isRequired = true,
    values = [],
    firstOption = null
}) {
    return (
        <div className='input-model-div select-input'>
            <div>
                <select required={isRequired} onChange={onChangeFun} name={name} id={id || name}>

                    {firstOption && <option value={firstOption.value || ''}>{firstOption.option}</option>}
                    {values.map((option, index) => <option selected={option?.selected} key={index} value={option.value || ''}>{option.option}</option>)}

                </select>
                <label htmlFor={id || name}>{label}</label>
            </div>
        </div>
    )
}

export default SelectInput