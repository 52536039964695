import React, { useEffect, useState } from 'react'
import './customer-ledgers.scss'
import SinglePage from '../../components/common/page/SinglePage'
import TableFilter from '../../components/common/table-filter/TableFilter'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import Modal from '../../components/common/modal/Modal'
import AddEditCustomerLedger from '../../components/user/customer-ledger-form/AddEditCustomerLedger'
import ImportLedger from '../../components/user/import-customer-ledger/ImportLedger'
import { accountingAxios } from '../../config/axios'
import { toast } from 'react-hot-toast'
import { BsDatabaseFillX, BsTrash3Fill } from 'react-icons/bs'
import { FiEdit2, FiDownloadCloud } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiLoaderAlt } from 'react-icons/bi'

function CustomerLedgers() {
    const [loading, setLoading] = useState('')
    const [data, setData] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false })

    const openModal = (content, title, width = null) => {
        setModal({ ...modal, content, title, status: true, width })
    }

    const handleDelete = (_id) => {
        const ask = window.confirm('Are you delete this ledger from XML ?')
        if (ask) {
            setLoading('delete' + _id)
            accountingAxios.delete(`/ledgers/customer?_id=${_id}`).then(() => {
                setData((state) => state.filter((item) => item._id !== _id))
                setLoading('')
            })
        }
    }

    useEffect(() => {
        setLoading('get-data')
        accountingAxios.get('/ledgers/customer').then((response) => {
            setData(response.data)
            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
        })
    }, [])

    return (
        <div className='customer-ledger-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Customer ledgers']}>
                {data[0] ?
                    <TableFilter srlNo={true} topRight={<div className='add-buttons-div'>
                        <button className='add-button' title='import XL file'
                            onClick={() => openModal(<ImportLedger setModal={setModal} setData={setData} />, 'Import XL file', '550px')}>
                            <FiDownloadCloud /> Import XL</button>
                        <button className='add-button' title='Create new'
                            onClick={() => openModal(<AddEditCustomerLedger setModal={setModal} setData={setData} />, 'Create New Ledger')}>
                            <AiOutlinePlus /> Create</button>
                    </div>} >
                        <table>
                            <thead>
                                <tr>
                                    <th>CID</th>
                                    <th>Name</th>
                                    <th>Customer</th>
                                    <th>Commission</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((customer, index) => (
                                    <tr key={index}>
                                        <td>{customer.cid}</td>
                                        <td>{customer.name}</td>
                                        <td>{customer.customers}</td>
                                        <td>{customer.commission}</td>
                                        <td>
                                            <div className="buttons">
                                                <button className='btn-icon-div bg_blue' title='Edit'
                                                    onClick={() => openModal(<AddEditCustomerLedger setModal={setModal} data={customer} setData={setData} />,
                                                        'Update Ledger')}><FiEdit2 /></button>
                                                <button className='btn-icon-div bg_red' title='Delete' onClick={() => handleDelete(customer._id)}>
                                                    {loading === ('delete' + customer._id) ? <span className='loading-icon'><BiLoaderAlt /></span> : <BsTrash3Fill />}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </TableFilter>
                    : <>
                        <Spinner icon={!loading && <BsDatabaseFillX />} message={loading ? 'Fetch data...' : 'No data'} spin={loading}
                            bottomContent={!loading && <div className='add-buttons-div'>
                                <button className='add-button'
                                    onClick={() => openModal(<AddEditCustomerLedger setModal={setModal} setData={setData} />, 'Create Ledger')}>
                                    <AiOutlinePlus />  Create</button>
                                <button className='add-button'
                                    onClick={() => openModal(<ImportLedger setModal={setModal} setData={setData} />, 'Import XL file', '550px')}>
                                    <FiDownloadCloud /> Import XL</button>
                            </div>} />
                    </>}
            </SinglePage>
        </div>
    )
}

export default CustomerLedgers