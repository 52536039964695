import React, { useEffect, useState } from 'react'
import './spares-list.scss'
import SinglePage from '../../components/common/page/SinglePage'
import TableFilter from '../../components/common/table-filter/TableFilter'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import Modal from '../../components/common/modal/Modal'
import SparesForm from '../../components/user/spares-form/SparesForm'
import ImportSpare from '../../components/user/import-spare/ImportSpare'
import { purifierAxios } from '../../config/axios'
import { toast } from 'react-hot-toast'
import { BsDatabaseFillX, BsTrash3Fill } from 'react-icons/bs'
import { FiEdit2, FiDownloadCloud } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiLoaderAlt } from 'react-icons/bi'

function SparesList() {
    const [loading, setLoading] = useState('')
    const [data, setData] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false })

    const openModal = (content, title, width = '650px') => {
        setModal({ ...modal, content, title, status: true, width })
    }

    const handleDelete = (_id) => {
        const ask = window.confirm('Are you delete this spare ?')
        if (ask) {
            setLoading('delete' + _id)
            purifierAxios.delete(`/spares?_id=${_id}`).then(() => {
                setData((state) => state.filter((item) => item._id !== _id))
                setLoading('')
            })
        }
    }

    useEffect(() => {
        setLoading('get-data')
        purifierAxios.get('/spares').then((response) => {
            setData(response.data)
            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
        })
    }, [])

    return (
        <div className='spares-list-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Purifier / Spares List']}>
                {data[0] ?
                    <TableFilter srlNo={true} topRight={<div className='add-buttons-div'>
                        {/* <button className='add-button' title='Import XL file'
                            onClick={() => openModal(<ImportSpare setModal={setModal} setData={setData} />, 'Import XL file', '600px')}>
                            <FiDownloadCloud /> Import XL</button> */}
                        <button className='add-button' title='Create new'
                            onClick={() => openModal(<SparesForm setModal={setModal} setData={setData} />, 'Create spare')}>
                            <AiOutlinePlus /> Create</button>
                    </div>} >
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th style={{ display: 'none' }}>Name for search</th>
                                    <th>Category</th>
                                    <th>Types</th>
                                    <th style={{ display: 'none' }}>Types for search</th>
                                    <th>Purchase <br></br> rate</th>
                                    <th>Normal rate</th>
                                    <th>SSP rate <br></br>(Discount)</th>
                                    <th>Warranty for</th>
                                    <th style={{ display: 'none' }}>Warranty for search</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((spare, index) => (
                                    <tr key={index} >
                                        <td style={{ textAlign: 'center' }} >{spare.spare_id}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <span className='name'>{spare.spare_name}</span>
                                            <span className='brand'>( {spare.brand} )</span>
                                            <span className='tally_name'>Tally : {spare.tally_spare_name}</span>
                                        </td>
                                        <td style={{ textAlign: 'center', display: 'none' }}>{spare.spare_name}  {spare.brand} {spare.tally_spare_name}</td>
                                        <td style={{ textAlign: 'center' }}>{spare.spare_category}</td>
                                        <td style={{ textAlign: 'center' }}>{spare?.spare_types.sort().map((type) => <span key={type}
                                            title={type} className={`text-badge ${type}-text`}>{type}</span>)}</td>
                                        <td style={{ textAlign: 'center', display: 'none' }}>{spare?.spare_types.map((type) => `${type} `)}</td>
                                        <td style={{ textAlign: 'center' }}>₹{spare.purchase_rate}</td>
                                        <td style={{ textAlign: 'center' }}>₹{spare.normal_rate}</td>
                                        <td style={{ textAlign: 'center' }}>₹{spare.ssp_rate} ({spare.ssp_disc_pct}%)</td>
                                        <td style={{ textAlign: 'center' }}>{spare?.warranty_for.sort().map((type) => <span key={type}
                                            title={type} className={`text-badge ${type}-text`}>{type}</span>)}
                                            <br></br>{spare?.warranty_period_days && spare?.warranty_for?.[0] ? `${spare?.warranty_period_days} days` : ""}
                                        </td>
                                        <td style={{ textAlign: 'center', display: 'none' }}>{spare?.warranty_for.map((type) => `${type} `)}</td>
                                        <td>
                                            <div className="buttons">
                                                <button className='btn-icon-div bg_blue' title='Edit'
                                                    onClick={() => openModal(<SparesForm setModal={setModal} data={spare} setData={setData} />,
                                                        'Update Ledger')}><FiEdit2 /></button>
                                                {/* <button className='btn-icon-div bg_red' title='Delete' onClick={() => handleDelete(spare._id)}>
                                                    {loading === ('delete' + spare._id) ? <span className='loading-icon'><BiLoaderAlt /></span> : <BsTrash3Fill />}
                                                </button> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </TableFilter>
                    : <>
                        <Spinner icon={!loading && <BsDatabaseFillX />} message={loading ? 'Fetch data...' : 'No data'} spin={loading}
                            bottomContent={!loading && <div className='add-buttons-div'>
                                <button className='add-button'
                                    onClick={() => openModal(<SparesForm setModal={setModal} setData={setData} />, 'Create spare')}>
                                    <AiOutlinePlus /> Create</button>
                                {/* <button className='add-button'
                                    onClick={() => openModal(<ImportSpare setModal={setModal} setData={setData} />, 'Import XL file', '550px')}>
                                    <FiDownloadCloud /> Import XL</button> */}
                            </div>} />
                    </>}
            </SinglePage>
        </div>
    )
}

export default SparesList