const formatAmount = (amount) => {
    amount = Number(amount)
    const parts = amount.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

const formatPhoneNumber = (number) => {
    const numberStr = number.toString();
    const formattedNumber = numberStr.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
    return formattedNumber;
}

const formatStringDate = (inputDate) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = new Date(inputDate).toLocaleDateString(undefined, options);
    return formattedDate;
}

const workTypeFormatChange = (workType) => {

    switch (workType) {
        case 'from_iw_amc_package_renewal':
        case 'from_ssp_amc_package_renewal':
        case 'from_amc_amc_package_renewal':
        case 'from_ow_amc_package_renewal':
            return 'AMC Package renewal'

        case 'from_iw_ssp_package_renewal':
        case 'from_amc_ssp_package_renewal':
        case 'from_ssp_ssp_package_renewal':
        case 'from_ow_ssp_package_renewal':
        case 'from_oc_ssp_package_renewal':
            return 'SSP Package renewal'

        case 'from_iw_carbon_change_without_package':
        case 'from_amc_carbon_change_without_package':
        case 'from_ssp_carbon_change_without_package':
        case 'from_ow_carbon_change_without_package':
            return 'Carbon Change without package'

        case 'from_ssp_periodical_service':
        case 'from_ow_periodical_service':
        case 'from_iw_periodical_service':
        case 'from_amc_periodical_service':
            return 'Periodical service'

        case 'from_ssp_complaint':
        case 'from_ow_complaint':
        case 'from_iw_complaint':
        case 'from_amc_complaint':
            return 'Complaint'

        default:
            return workType;
    }
}

const numberInWord = (amount) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const thousands = ['', 'Thousand', 'Million', 'Billion', 'Trillion']; // Extend as needed

    function convertThreeDigits(num) {
        const numStr = num.toString();
        const [a, b, c] = numStr.padStart(3, '0').split('').map(Number);

        let result = '';
        if (a > 0) {
            result += units[a] + ' Hundred ';
        }
        if (b === 1) {
            result += teens[c] + ' ';
        } else {
            result += tens[b] + ' ' + units[c] + ' ';
        }
        return result;
    }

    function convertToWords(amount) {
        if (amount === 0) {
            return 'Zero';
        }

        const amountStr = amount.toString();
        const chunks = [];

        for (let i = amountStr.length; i > 0; i -= 3) {
            chunks.push(amountStr.slice(Math.max(0, i - 3), i));
        }

        let words = '';
        for (let i = chunks.length - 1; i >= 0; i--) {
            const chunk = parseInt(chunks[i]);
            if (chunk === 0) continue;

            words += convertThreeDigits(chunk) + thousands[i] + ' ';
        }

        return words.trim();
    }

    return convertToWords(amount);
}

const YYYYMMDDFormat = (ISOdate, symbol = '-') => {
    symbol = symbol ? symbol : ''
    const year = ISOdate?.getFullYear();
    const month = String(ISOdate.getMonth() + 1).padStart(2, '0');
    const day = String(ISOdate.getDate()).padStart(2, '0');

    return `${year}${symbol}${month}${symbol}${day}`;
}

export { formatAmount, formatPhoneNumber, formatStringDate, workTypeFormatChange, numberInWord, YYYYMMDDFormat }