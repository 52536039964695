import React, { useState } from 'react'
import NormalInput from '../../common/inputs/NormalInput'
import './add-edit-customer-ledger.scss'
import { BiLoaderAlt } from 'react-icons/bi'
import { accountingAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'

function AddEditCustomerLedger({ data, setData, setModal }) {
    const [form, setForm] = useState(data || {})
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if (data) {
            accountingAxios.put('/ledgers/customer', form).then(() => {
                setData((state) => state.map((item) => {
                    if (item._id === form._id) {
                        return form
                    }
                    return item
                }))
                setModal((state) => ({ ...state, status: false }))
                toast.success('Ledger Updated')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        } else {
            accountingAxios.post('/ledgers/customer', form).then((response) => {
                setData((state) => [response.data, ...state])
                setModal((state) => ({ ...state, status: false }))
                toast.success('Ledger Created')
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message)
                setLoading(false)
            })
        }
    }

    return (
        <div className='add-edit-customer'>
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='Customer ID' type={'number'} name='cid' value={form?.cid || ''} onChangeFun={handleChange} />
                <NormalInput label='Name' name='name' value={form?.name || ''} onChangeFun={handleChange} />
                <NormalInput label='Customer Ledger Name' name='customers' value={form?.customers || ''} onChangeFun={handleChange} />
                <NormalInput label='Commission Ledger Name' name='commission' value={form?.commission || ''} onChangeFun={handleChange} />

                <div className="button-div">
                    <button>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : data ? 'Update' : 'Create'}</button>
                </div>
            </form>
        </div>
    )
}

export default AddEditCustomerLedger