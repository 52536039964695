import React, { useEffect, useState } from 'react'
import './style.scss'
import SinglePage from '../../components/common/page/SinglePage'
import { purifierAxios } from '../../config/axios'
import toast from 'react-hot-toast'
import TableFilter from '../../components/common/table-filter/TableFilter'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import Modal from '../../components/common/modal/Modal'
import AddEditProduct from '../../components/user/add-edit-pr-product/AddEditProduct'
import { BsDatabaseFillX } from 'react-icons/bs'
import { FiEdit2, } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'


const PurifierProductList = () => {
    const [data, setData] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [loading, setLoading] = useState('')

    const openModal = (content, title) => {
        setModal({ ...modal, content, title, status: true })
    }

    useEffect(() => {
        setLoading('fetch')
        purifierAxios.get('/products?all=YES').then((response) => {
            setData(response.data)
            setLoading('')
        }).catch((error) => {
            setLoading('')
            toast.error(error.message)
        })
    }, [])


    return (
        <div className="purifier-product-list-div">
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Purifier / Product list']}>
                {data?.[0] ? <TableFilter srlNo={true} topRight={<div className='add-buttons-div'>
                    <button className='add-button' title='Create new'
                        onClick={() => openModal(<AddEditProduct setData={setData} setModal={setModal} />, 'CREATE PRODUCT')} >
                        <AiOutlinePlus /> Create</button>
                </div>}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Category</th>
                                <th>AMC</th>
                                <th>SSP</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((product) => <tr>
                                <td>{product?.product_name}</td>
                                <td>{product?.product_category}</td>
                                <td>Fund : ₹{product?.amc_fund} <br></br> Spare : ₹{product?.amc_spare_amt}</td>
                                <td>Fund : ₹{product?.ssp_fund} <br></br> Spare : ₹{product?.ssp_spare_amt}</td>
                                <td>  <div className="buttons"><button className='btn-icon-div bg_blue' title='Edit'
                                    onClick={() => openModal(<AddEditProduct product={product} setData={setData} setModal={setModal} />,
                                        'UPDATE PRODUCT')}><FiEdit2 /></button></div> </td>
                            </tr>)}
                        </tbody>
                    </table>
                </TableFilter>
                    :
                    <Spinner icon={!loading && <BsDatabaseFillX />} message={loading ? 'Fetch data...' : 'No data'} spin={loading}
                        bottomContent={!loading && <div className='add-buttons-div'>
                            <button className='add-button'
                                onClick={() => openModal(<AddEditProduct setData={setData} setModal={setModal} />, 'CREATE PRODUCT')}>
                                <AiOutlinePlus /> Create</button>
                        </div>} />}
            </SinglePage >
        </div >
    )
}

export default PurifierProductList