import React, { useEffect, useState } from 'react'
import './single-service.scss'
import { useNavigate, useParams } from 'react-router-dom'
import SinglePage from '../../components/common/page/SinglePage'
import SpinWithMessage from '../../components/common/spinners/SpinWithMessage'
import WorkTypeSpares from '../../components/user/work-type-spare-list/WorkTypeSpares'
import BillPdf from '../../components/user/build-pdf/BillPdf'
import ReceiptPdf from '../../components/user/build-pdf/ReceiptPdf'
import { accountingAxios } from '../../config/axios'
import { pdf } from '@react-pdf/renderer';
import { FaDotCircle } from 'react-icons/fa'
import { LuIndianRupee } from 'react-icons/lu'
import { GiTransparentTubes } from 'react-icons/gi'
import { BsReceiptCutoff } from 'react-icons/bs'
import { TbReceipt2 } from 'react-icons/tb'
import { BiLoaderAlt } from 'react-icons/bi'
import { formatStringDate, workTypeFormatChange, formatAmount } from '../../assets/javascript/formate-functions'


function SingleService() {
    const { srl_number } = useParams()
    const [data, setData] = useState({})
    const navigate = useNavigate()
    const [loading, setLoading] = useState('')
    const [totalConfirmAmount, setTotalConfirmAmount] = useState(0)


    const buildBill = async () => {
        setLoading('bill')
        const pdfDoc = (<BillPdf data={data} />);
        const pdfBlob = await pdf(pdfDoc).toBlob();
        downloadPdf(pdfBlob, `Bill-${data?.service_srl_number}`)
    }

    const buildReceipt = async () => {
        setLoading('receipt')
        const pdfDoc = (<ReceiptPdf data={data} totalConfirmAmount={totalConfirmAmount} />);
        const pdfBlob = await pdf(pdfDoc).toBlob();
        downloadPdf(pdfBlob, `Receipt-${data.service_srl_number}`)
    }

    const downloadPdf = (blobData, fileName) => {
        const pdfUrl = URL.createObjectURL(blobData);

        // Create an anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';

        // Trigger the download programmatically
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            // For iPhone/iPad devices, use the 'click' event instead of 'download'
            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            link.dispatchEvent(clickEvent);

        } else {
            // For other devices, set the 'download' attribute and click the link
            link.download = `${fileName}.pdf`;
            link.click();
        }
        setLoading('')
    }

    useEffect(() => {
        if (srl_number) {
            setLoading('fetch');
            accountingAxios.get(`/purifier-service-list/?srl_number=${srl_number}`).then((response) => {
                if (response.data) {
                    setData(response.data)
                    let total = 0
                    response.data?.payment_history?.map((tran) => {
                        if (tran.confirm) {
                            total += tran.total_amount
                        }
                    })
                    setTotalConfirmAmount(total)
                    setLoading('')
                } else {
                    navigate('/')
                    setLoading('')
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className='single-service-div'>
            <SinglePage titleArray={[`Purifier service list / ${srl_number}`]}>
                {loading === 'fetch'
                    ? <SpinWithMessage message={'Fetch data...'} />
                    :
                    <>
                        <div className="top-div">
                            <div className="buttons-div">
                                <button onClick={() => buildBill()}>{loading === 'bill'
                                    ? <span className='loading-icon'><BiLoaderAlt /></span>
                                    : <BsReceiptCutoff />} Bill</button>
                                {0 < totalConfirmAmount ? <button onClick={() => buildReceipt()}>{loading === 'receipt'
                                    ? <span className='loading-icon'><BiLoaderAlt /></span>
                                    : <TbReceipt2 />} Receipt</button> : ''}

                            </div>
                        </div>
                        <div className="single-border">
                            <div className="left">
                                {/* Service Details */}
                                <div className="card-div">
                                    <div className="head-div">
                                        <FaDotCircle />
                                        <h4>Service details</h4>
                                    </div>
                                    <div className="content-div">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>: {formatStringDate(data?.date)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Srl no</td>
                                                    <td>: {data?.service_srl_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>CID</td>
                                                    <td>: {data?.cid}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>: {data?.name_of_customer}</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>: <span className={` ${data?.purifier_customer_status}-text text-badge`}>{data?.purifier_customer_status}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Technician</td>
                                                    <td>: {data?.technician}</td>
                                                </tr>
                                                <tr>
                                                    <td>Work method</td>
                                                    <td>: {data?.work_method}</td>
                                                </tr>
                                                {data?.current_ssp_card_no && <tr>
                                                    <td>SSP Card no</td>
                                                    <td>: {data?.current_ssp_card_no}</td>
                                                </tr>}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* Receipt details  */}
                                <div className="card-div">
                                    <div className="head-div">
                                        <FaDotCircle />
                                        <h4>Receipt details</h4>
                                    </div>
                                    <div className="content-div">
                                        {Number(data?.bill_data?.total_receivable)
                                            ?
                                            <table>
                                                <tbody>
                                                    {data?.bill_data?.ssp_fund && <tr>
                                                        <td>SSP Fund</td>
                                                        <td>: ₹{data?.bill_data?.ssp_fund}</td>
                                                    </tr>}
                                                    {data?.bill_data?.amc_fund && < tr >
                                                        <td>AMC Fund</td>
                                                        <td>: ₹{data?.bill_data?.amc_fund} </td>
                                                    </tr>}
                                                    <tr>
                                                        <td>Primary amount</td>
                                                        <td>: ₹{data?.bill_data?.primary_spare_total_receivable || 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Secondary amount</td>
                                                        <td>: ₹{data?.bill_data?.secondary_spare_total_receivable || 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Special work amount</td>
                                                        <td>: ₹{data?.bill_data?.special_work_spare_total_receivable || 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Service charge</td>
                                                        <td>: ₹{data?.bill_data?.service_charge_receivable}</td>
                                                    </tr>
                                                    {data?.bill_data?.extra_charge_receivable ? <tr>
                                                        <td>Extra service charge</td>
                                                        <td>: ₹{data?.bill_data?.extra_charge_receivable}</td>
                                                    </tr> : ''}
                                                    {data?.bill_data?.compliment_given_now ? <>
                                                        <tr className='underline'>
                                                            <td>Total Amount</td>
                                                            <td>: {data?.bill_data?.total_receivable || 0}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Compliment given</td>
                                                            <td>: -{data?.bill_data?.compliment_given_now}</td>
                                                        </tr>
                                                    </> : ""}
                                                    <tr className='underline-bold bold-text'>
                                                        <td>Grand Total</td>
                                                        <td>: ₹{formatAmount(data?.bill_data?.grand_total_receivable)}</td>
                                                    </tr>
                                                    <tr><td></td></tr>
                                                    <tr><td></td></tr>
                                                    <tr>
                                                        <td>Received amount</td>
                                                        <td>: ₹{formatAmount(data?.total_amount_received)}</td>
                                                    </tr>
                                                    {data?.payment_history?.[0] ? <>
                                                        <tr>
                                                            <td>Received method</td>
                                                            <td>: {data?.payment_history[0]?.received_method}</td>
                                                        </tr>
                                                    </> : ''}
                                                    {data?.balance_amount > 0 ? <>
                                                        <tr className='bold-text'>
                                                            <td>Pending amount</td>
                                                            <td>: {data?.balance_amount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Balance will pay on</td>
                                                            <td>: {formatStringDate(data?.payment_history?.[0] ? data?.payment_history[data?.payment_history.length - 1]?.balance_due_date : '')}</td>
                                                        </tr>
                                                    </> : ''}

                                                    {totalConfirmAmount !== data?.total_amount_received && <tr className='underline'>
                                                        <td>Confirmed amount</td>
                                                        <td>: ₹{formatAmount(totalConfirmAmount)}</td>
                                                    </tr>}

                                                </tbody>
                                            </table>
                                            : <>
                                                <div className="no-receipt-div">
                                                    <div className="receipt-border">
                                                        <div className="box">
                                                            < LuIndianRupee />
                                                            <h5>No Cash deals</h5>
                                                            <p>You cannot download receipt for customer</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                {data?.primary_spares?.[0] &&
                                    <WorkTypeSpares type={'Primary'} data={data?.primary_spares} total={data?.bill_data?.primary_spare_total_receivable || 0} />
                                }
                                {data?.secondary_spares?.[0] &&
                                    <WorkTypeSpares type={'Secondary'} data={data?.secondary_spares} total={data?.bill_data?.secondary_spare_total_receivable || 0} />
                                }
                                {data?.special_spares?.[0] &&
                                    <WorkTypeSpares type={'Special work'} data={data?.special_spares} total={data?.bill_data?.special_work_spare_total_receivable || 0} />
                                }
                                {!data?.primary_spares?.[0] && !data?.secondary_spares?.[0] &&
                                    !data?.special_spares?.[0] &&
                                    <div className="no-receipt-div">
                                        <div className="receipt-border">
                                            <div className="box">
                                                < GiTransparentTubes />
                                                <h5>No Spare changes</h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </>}
            </SinglePage>
        </div >
    )
}

export default SingleService