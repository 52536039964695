import React, { useEffect, useState } from 'react'
import './wh-page.scss'
import SinglePage from '../../components/common/page/SinglePage'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../components/common/modal/Modal'



const WholeHousePage = () => {
    const navigate = useNavigate()
    const [modal, setModal] = useState({ content: null, title: null, status: false })


    return (
        <div className="purifier-page-div">
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Whole house']}>
                <div className="border">
                    <div className="button-div" >
                        <button onClick={() => navigate('/whole-house/product-list')} >PRODUCT LIST
                        </button>
                    </div>

                </div>
            </SinglePage >
        </div>
    )
}

export default WholeHousePage