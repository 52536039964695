import React from 'react'
import { Page, View, Image, StyleSheet, Text } from '@react-pdf/renderer';
import WaterMark from '../../../../assets/images/logo-gray.png'
import Logo from '../../../../assets/images/full-logo.png'
import { FaStar, FaHeart } from 'react-icons/fa';


function PageWrapper({ children, receipt }) {

    const styles = StyleSheet.create({
        page: {
            fontFamily: 'PdfFontFamily',
            fontWeight: 400,
            paddingTop: '80pt',
            paddingBottom: '80pt',
        },
        container: {
            paddingLeft: '40pt',
            paddingRight: '40pt',
        },
        waterMarkImage: {
            width: '400px',
            height: '400px',
            opacity: '.1'
        },
        watermark: [{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: '25px',
            top: '250px',
            zIndex: -1

        }, {
            alignItems: 'center',
        }],
        topBorder: {
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0
        },
        topBar: {
            width: '100%',
            height: '20px',
            backgroundColor: '#ac7c1c',

        },
        topHeder: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingLeft: '20pt',
            paddingRight: '50pt',
            paddingTop: '10px'
        },
        logoImage: {
            width: '200px'
        },
        textDiv: {
            textAlign: 'center',
            marginTop: '20px'


        },
        bottomBorder: {
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            marginTop: '25px'
        },
        bottomBar: {
            width: '100%',
            height: '5px',
            backgroundColor: '#ac7c1c',
        },
        footer: {
            paddingLeft: '40pt',
            paddingRight: '40pt',
        },
        smallText: {
            fontSize: '9px',
            color: '#969696',
            fontWeight: 400
        },
        malayalamText: {
            fontSize: '8px',
            fontWeight: 1400
        },
        contactInfo: {
            borderTopColor: '#474747',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            width: '100%',
            textAlign: 'center',
            fontSize: '11px',
            paddingTop: '3px',
            paddingBottom: '3px',
            marginTop: '3px',
            fontWeight: 500
        }


    });
    return (

        <Page size="A4" style={styles.page}>
            <View style={styles.topBorder} fixed>
                <View style={styles.topBar} ></View>
                <View style={styles.topHeder}>
                    <View>
                        <Image style={styles.logoImage} src={Logo} />
                    </View>
                    <View style={{ textAlign: 'center' }}>
                        <Text style={{ fontWeight: 800, color: '#757575' }}>{receipt ? "Receipt" : 'Sales Bill'}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.container}>
                {children}
            </View>
            <View style={styles.watermark} fixed>
                <Image style={styles.waterMarkImage} src={WaterMark} fixed />
            </View>
            <View style={styles.bottomBorder} fixed>
                <View style={styles.footer}>
                    <Text style={styles.smallText}>* Receipt should be obtained after payment.</Text>
                    <Text style={styles.smallText}>* If you have any questions, please contact us at +91 953 953 9453 or email us at
                        email@alliancewatersolutions.com.</Text>
                    <Text style={[styles.smallText, styles.malayalamText]}>* പണമിടപാടുകൾക്ക് ശേഷം നിർബന്ധമായും രസീത് കൈപറ്റുക.</Text>
                    <Text style={[styles.smallText, styles.malayalamText]}>* സംശയങ്ങൾക്ക് ബന്ധപ്പെടുക, Mobile : +91 953 953 9453 , Mail : email@alliancewatersolutions.com.</Text>
                </View>
                <View style={styles.contactInfo}>
                    <Text> Ph : +91 953 953 9453  |  Website : www.alliancewatersolutions.com</Text>
                </View>
                <View style={styles.bottomBar} ><FaHeart /></View>
            </View>
        </Page>

    )
}


export default PageWrapper