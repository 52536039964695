import React from 'react'
import './style.scss'



const WorkCreditHistory = ({ payment_history }) => {
    return (
        <div className="work-credit-history-div">
            {payment_history.map((history, index) => <div key={index} className="history-card-div">
                <div className={(history?.confirm || history?.total_amount === 0) ? "confirm-div confirmed" : "confirm-div"}>
                    <h3>{(history?.confirm || history?.total_amount === 0)
                        ? history?.auto_confirm
                            ? 'Auto Confirm'
                            : 'Confirmed'
                        : history?.rejected
                            ? "Rejected"
                            : 'Pending'}</h3>
                </div>
                <div className="content-div">
                    <div className="line-section-div">
                        <p>Payment ID</p>
                        <p>#{history?.pay_id || 'Nill'}</p>
                    </div>
                    <div className="line-section-div">
                        <p>Received date</p>
                        <p>{history?.received_date || 'Nill'}</p>
                    </div>
                    <div className="line-section-div">
                        <p>Payment Method</p>
                        <p>{history?.received_method || 'Nill'}</p>
                    </div>
                    <div className="line-section-div">
                        <p>Total amount</p>
                        <p>₹{history?.total_amount || '0'}</p>
                    </div>
                    {history?.balance_due_date && <>
                        <div className="line-section-div">
                            <p>Balance due date</p>
                            <p>{history?.balance_due_date || 'Nill'}</p>
                        </div>
                        <div className="line-section-div">
                            <p>Reason</p>
                            <p>{history?.pending_reason || 'Nill'}</p>
                        </div>
                    </>}
                </div>
            </div>)}

        </div>
    )
}

export default WorkCreditHistory