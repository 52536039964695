import React, { useEffect, useState } from 'react'
import './compliment-list.scss'
import SinglePage from '../../components/common/page/SinglePage'
import TableFilter from '../../components/common/table-filter/TableFilter'
import Modal from '../../components/common/modal/Modal'
import ComplimentFrom from '../../components/user/compliment-form/ComplimentFrom'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import { accountingAxios } from '../../config/axios'
import { formatAmount } from '../../assets/javascript/formate-functions'
import { AiOutlinePlus } from 'react-icons/ai'
import { PiPlusMinusBold } from 'react-icons/pi'
import { BsDatabaseFillX } from 'react-icons/bs'
import { FaStar } from 'react-icons/fa'


function ComplimentList() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState('')
    const [total, setTotal] = useState(0)
    const [custCount, setCustCount] = useState(0)
    const [modal, setModal] = useState({ content: null, title: null, status: false })

    const openModal = (content, title) => {
        setModal({ ...modal, content, title, status: true })
    }

    useEffect(() => {
        setCustCount(data.length)
    }, [data])


    useEffect(() => {
        setLoading('fetch')
        accountingAxios.get('/compliment-amount').then((response) => {
            setLoading('')
            setTotal(response.data.total_amount)
            setData(response?.data?.customers)
        })
    }, [])

    return (
        <div className='compliment-list-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Compliment list']}>
                {data?.[0] ?
                    <>
                        <div className="compliments-top">
                            <div className="top-border">
                                <div className="count-card">
                                    <h3>₹{formatAmount(total)}</h3>
                                    <p>Total Amount</p>
                                </div>
                                <div className="count-card">
                                    <h3 >{custCount}</h3>
                                    <p>No of Customers</p>
                                </div>
                            </div>

                        </div>
                        <div className="table-div">
                            <TableFilter srlNo={true} topRight={<button className='add-button' title='Create new'
                                onClick={() => openModal(<ComplimentFrom setTotal={setTotal} setModal={setModal} setData={setData} />, 'Create a compliment')}>
                                <AiOutlinePlus /> Create</button>}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>CID</th>
                                            <th>Full name</th>
                                            <th style={{ display: 'none' }}></th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((customer) => {
                                            return <tr key={customer.cid}>
                                                <td>{customer.cid}</td>
                                                <td><span>{customer.first_name} {customer.last_name}
                                                    <small className={`text-badge green-text`}><FaStar /> {customer?.star_rate || 0}</small>
                                                </span><br></br>{customer?.address?.address}</td>
                                                <td style={{ display: 'none' }}>{customer.first_name} {customer.last_name}</td>
                                                <td style={{ color: customer.debit_amount > 0 ? 'black' : 'red' }}>₹{customer.debit_amount}</td>
                                                <td> <div className="buttons">
                                                    <button className='btn-icon-div bg_blue' title='Tally'
                                                        onClick={() => openModal(<ComplimentFrom setTotal={setTotal} setModal={setModal} data={customer} setData={setData} />,
                                                            'Tally compliment')}><PiPlusMinusBold /></button>
                                                </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </TableFilter>
                        </div>
                    </>
                    : <>
                        <Spinner icon={loading !== 'fetch' && <BsDatabaseFillX />} message={loading === 'fetch' ? 'Fetch data...' : 'No compliments'} spin={(loading && true)}
                            bottomContent={loading !== 'fetch' && <div className='add-buttons-div'>

                                <button className='add-button'
                                    onClick={() => openModal(<ComplimentFrom setTotal={setTotal} setModal={setModal} setData={setData} />, 'Create a compliment')}>
                                    <AiOutlinePlus /> Create</button>
                            </div>} />
                    </>}
            </SinglePage>
        </div >
    )
}

export default ComplimentList