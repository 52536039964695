import React, { useState } from 'react'
import './add-edit-product.scss'
import NormalInput from '../../common/inputs/NormalInput'
import SelectInput from '../../common/inputs/SelectInput'
import { purifierProductCategory } from '../../../assets/javascript/const-data'
import { BiLoaderAlt } from 'react-icons/bi'
import { purifierAxios } from '../../../config/axios'
import toast from 'react-hot-toast'

const AddEditProduct = ({ product, setData, setModal }) => {
    const [form, setForm] = useState(product || {})
    const [loading, setLoading] = useState('')

    const categoryList = purifierProductCategory.map((option) => {
        return {
            option: option,
            value: option,
            selected: product?.product_category === option
        }
    })

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading('submit')
        if (product) {
            purifierAxios.put('/products', form)
                .then(() => {
                    setData((state) => state.map((obj) => {
                        if (obj._id === form._id) {
                            return form
                        }
                        return obj
                    }))
                    setLoading('')
                    setModal({ status: false })
                    toast.success('Updated')
                })
                .catch((error) => {
                    setLoading('')
                    toast.error(error.message)
                })
        } else {
            purifierAxios.post('/products', form)
                .then((response) => {
                    setData((state) => [response.data, ...state])
                    setLoading('')
                    setModal({ status: false })
                })
                .catch((error) => {
                    setLoading('')
                    toast.error(error.message)
                })
        }

    }


    return (
        <div className="add-edit-product-div">
            <form action="" onSubmit={handleSubmit}>
                <div className="form-main-content">
                    <NormalInput label='Product name' type={'text'} name='product_name' value={form.product_name || ''} onChangeFun={handleChange} />
                    <SelectInput label='Product category' name='product_category' firstOption={{ option: 'Choose', value: '' }} values={categoryList} onChangeFun={handleChange} />
                    <NormalInput label='AMC Fund' type={'number'} name='amc_fund' value={`${form.amc_fund}` || ''} onChangeFun={handleChange} />
                    <NormalInput label='SSP Fund' type={'number'} name='ssp_fund' value={`${form.ssp_fund}` || ''} onChangeFun={handleChange} />
                    <NormalInput label='AMC spare round amount' type={'number'} name='amc_spare_amt' value={`${form.amc_spare_amt}` || ''} onChangeFun={handleChange} />
                    <NormalInput label='SSP spare round amount' type={'number'} name='ssp_spare_amt' value={`${form.ssp_spare_amt}` || ''} onChangeFun={handleChange} />
                </div>
                <div className="button-div">
                    <button>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : product ? 'Update' : 'Create'}</button>
                </div>
            </form>
        </div>
    )
}

export default AddEditProduct