import React from 'react'
import './tally.scss'
import SinglePage from '../../components/common/page/SinglePage'
import { useNavigate } from 'react-router-dom'

function Tally() {
    const navigate = useNavigate()

    return (
        <div className="tally-page-div">
            <SinglePage titleArray={['Tally']}>
                <div className="tally-border">
                    <div className="button-div" >
                        <button onClick={() => navigate('/tally/xml')} >BUILD XML
                        </button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/tally/customer-ledgers')} >CUSTOMER LEDGERS
                        </button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/tally/technician-ledgers')} >TECHNICIAN LEDGERS
                        </button>
                    </div>
                </div>
            </SinglePage>
        </div>
    )
}

export default Tally